import * as React from 'react';

import type { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { HeroBlock } from 'stile-shared/src/components/2020/blocks/HeroBlock';
import { MediaBlock } from 'stile-shared/src/components/2020/blocks/MediaBlock';
import { TeaserBlock } from 'stile-shared/src/components/2020/blocks/TeaserBlock';
import { TextBlock } from 'stile-shared/src/components/2020/blocks/TextBlock';
import { P, Ul } from 'stile-shared/src/components/2020/common/Primitives';
import { Column } from 'stile-shared/src/components/2020/layout/Column';

import { getPageData } from 'utils/getPageData';

import { theme } from 'styles/theme';

import { PageLayout } from 'templates/2020/PageLayout';

import { CtaBlock } from 'components/blocks/CtaBlock';
import { NextPageBlock } from 'components/blocks/NextPageBlock';

export { Head } from 'templates/2020/Head';

function StemPage(props: PageProps) {
  const { hero } = getPageData(props.location.pathname);

  return (
    <PageLayout {...props}>
      <HeroBlock
        {...hero}
        image={
          <StaticImage
            loading="eager"
            aspectRatio={2.37}
            src="../../../../stile-shared/assets/images/stem/cover_stem.png"
            alt="A student engaged with a question from their teacher"
          />
        }
      />

      <TextBlock title="The science classroom is the natural home of STEM">
        <P>
          Great science teaching reinforces the fundamental math required for effective science
          practice. It should also draw direct links between scientific discovery and the practical
          technologies that result. Students should graduate from school with a working knowledge of
          the engineering practice that turns science into technology.
        </P>
      </TextBlock>

      <MediaBlock layout="montage-two">
        <StaticImage
          src="../../../../stile-shared/assets/images/stem/stem_01.png"
          alt="A student holds up a drone, with another student and an adult looking on"
        />
        <StaticImage
          src="../../../../stile-shared/assets/images/stem/stem_02.png"
          alt="A student raises their hand, turning to face someone off camera"
        />
      </MediaBlock>

      <TextBlock variant="dark" title="Science does not exist in a vacuum" space={theme.space.m}>
        <P>
          We conceptualize STEM in a different acronym order:
          <br />
          <strong>Math → Science → Engineering → Technology</strong>.
        </P>

        <Column space={theme.space.s}>
          <P>These disciplines build on each other:</P>

          <Ul>
            <li>
              Math is the language of science; it’s a core tool for essentially all scientific
              endeavours.
            </li>
            <li>
              Science is the basic research we do to critically evaluate the world around us and, by
              doing so, generate new ideas.
            </li>
            <li>
              Engineering is the development of those novel ideas into something useful – the
              technology that improves our lives.
            </li>
          </Ul>
        </Column>

        <P>
          Scientific literacy cannot be achieved unless these relationships are internalized by
          students.
        </P>
      </TextBlock>

      <TeaserBlock
        contentSide="right"
        title="Math: The language of science"
        image={
          <StaticImage
            src="../../../../stile-shared/assets/images/stem/spot_maths_background.png"
            alt="An illustration of a student sitting cross-legged and working on a laptop. There is various mathematical symbols and diagrams in the background"
          />
        }
      >
        <Column space={theme.space.m}>
          <P>
            Stile’s teaching resources reinforce and contextualize the core numeracy skills students
            are learning separately in math class
          </P>
          <P>
            We provide revision materials for these skills, particularly around statistics and data
            interpretation, and build on these skills to explore more challenging scientific
            concepts.
          </P>
          <P>
            This supports school-wide numeracy initiatives, and helps students understand the
            relevance of math.
          </P>
        </Column>
      </TeaserBlock>

      <TeaserBlock
        title="Engineering: Ideas into practical technologies"
        image={
          <StaticImage
            src="../../../../stile-shared/assets/images/stem/diagram_engineering_diagram.png"
            alt="A handwritten flowchart with the sequence 'Define, Brainstorm, Research, Design, Create, Test & Evaluate'. Each stage has hand drawn symbols alongside it"
          />
        }
      >
        <Column space={theme.space.m}>
          <P>
            Technology isn’t just a smartphone. It’s vaccinations, genetic modification and wind
            turbines. Stile’s lessons draw direct links between these real-world technologies and
            the science that underpins them
          </P>
          <P>
            Engineering Challenges guide students through the process of creating novel solutions to
            problems and STEM career profiles show the interesting ways relatable people are
            applying these skills every day.
          </P>
        </Column>
      </TeaserBlock>

      <NextPageBlock path="/why-choose-stile/boost-reading-writing-and-math/" />

      <CtaBlock />
    </PageLayout>
  );
}

export default StemPage;
